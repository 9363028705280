//
// _pricing.scss
//
.pricing {
    transition: all 0.5s ease;
    &:hover {
        transform: translateY(-10px);
        box-shadow: $shadow-md !important;
    }
    &.business-plan {
        transform: scale(1.05);
        z-index: 1;
    }
}

.brokerage-form {
    label {
        font-size: 18px;
        font-weight: 600 !important;
    }
}

input[type="range"]:focus {
    box-shadow: none !important;
    background-color: transparent !important;
}
    
.custom-range {
    height: 0.5rem !important;
    &::-webkit-slider-thumb {
        background-color: $primary !important;
    }
    &::-moz-range-thumb {
        background-color: $primary !important;
    }
    &::-ms-thumb {
        background-color: $primary !important;
    }
}