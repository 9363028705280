//
// _feature.scss
//
.features {
    @each $name,
    $value in $colors {
        &.feature-#{$name} {
            .title {
                &:hover {
                    color: $value !important;
                }
            }
            .icon {
                color: $value !important;
                background-color: rgba($value, 0.05) !important;
            }
            .link {
                color: $value !important;
            }
        }
    }
}

.features {
    &.core-feature {
        transition: all 0.5s ease;
        &:hover {
            transform: translateY(-10px);
            box-shadow: $shadow-lg !important;
        }
    }
    .icon {
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icons {
            line-height: 68px;
        }
    }
}


//Key Features
.key-feature {
    @each $name,
    $value in $colors {
        &.feature-#{$name} {
            .icon {
                color: $value !important;
                background: linear-gradient(45deg, transparent, rgba($value, 0.1));
            }
        }
    }
}

.key-feature {
    transition: all 0.5s ease;
    .icon {
        height: 45px;
        width: 45px;
        line-height: 45px;
    }
    &:hover {
        box-shadow: $shadow-md !important;
    }
}

//Features Absolute
.features-absolute {
    position: relative;
    margin: -200px 0 0px;
    z-index: 2;
    transition: all 0.5s ease;

    @media (max-width: 768px) {
        margin: -140px 0 0;
    }

    &.career-form {
        margin: -145px 0 0px;
        
        @media (max-width: 768px) {
            margin: -105px 0 0px;
        }
    }
}