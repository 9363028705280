//
// _footer.scss
//
footer {
    padding: 60px 0;
    position: relative;
    color: $gray-500;
    .text-foot {
        color: $gray-500;
    }
    .footer-head {
        font-size: 18px !important; 
        letter-spacing: 1px;         
        font-weight: 500;
    }
    .footer-list {
        margin-bottom: 0;
        li {
            margin-bottom: 10px;
            margin-left: 0 !important;
            a {
                transition: all 0.5s ease;
                &:hover {
                    color: lighten($gray-500, 20%);
                }
            }   
            &:last-child{
                margin-bottom: 0;
            }         
        }
    } 
    .footer-bar,
    &.footer-bar {
        border-top: 1px solid lighten($footer, 6%);
    }
}