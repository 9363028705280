//
// helper.scss
//
.section {
    padding: 100px 0;
    position: relative;

    @media (max-width: 768px) {
        padding: 60px 0;
    }
}
.section-two {
    padding: 60px 0;
    position: relative;
}
.bg-overlay {
    background-color: $overlay;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

//Title
.heading-title {
    line-height: 26px;
    .heading {
        font-size: 42px !important;
        letter-spacing: 1px;
    }
    .title {
        font-size: 32px !important;
        letter-spacing: 1px;
    }
    .para-desc {
        font-size: 18px;
    }
}
.section-title {
    position: relative;
    .title {
        letter-spacing: 0.5px;
        font-size: 28px !important;
    }
}
.para-desc {
    max-width: 600px;
}
.mt-100 {
    margin-top: 100px;
}
//Shapes
.shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: -1px;
    left: 0;

    @media (max-width: 425px) {
        bottom: -4px;
    }

    &>svg {
        transform: scale(2);
        width: 100%;
        height: auto;
        transform-origin: top center;
    }
    &.seo-hero {
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        height: 250px;
        background: $white;

        @media (max-width: 768px) {
            height: 140px;
        }

        @media (max-width: 425px) {
            height: 60px;
        }
    }
}

.mover {
    animation: mover 1s infinite alternate;
}
@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(5px);
    }
}

//Spinner Class
.spin-anything {
    animation: spinner-animation 5s linear infinite
}

@keyframes spinner-animation {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@media (min-width: 768px) {
    .margin-top-100 {
        margin-top: 100px;
        .heading {
            font-size: 40px !important;
        }
    }
}

@media (max-width: 768px) {
    .heading-title {
        .heading {
            font-size: 36px !important;
        }
        .para-desc {
            font-size: 18px;
        }
    }
    .section-title {
        .title {
            font-size: 24px !important;
        }
    }
    .mt-60 {
        margin-top: 60px;
    }
}

ul li{
    -webkit-margin-start: 0.25rem;
            margin-inline-start: 0.25rem;
  }
  ul li:first-child{
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
  }