.pricing-tag>ul li::before {
    content: url('../public/download.svg') ' ';
    color: #4259ed !important;
 
  }
.pricing-tag>ul li {
    font-size: 18px !important;
    list-style: none;
    margin-inline-start: 0px;

 
  }
 