//
// _team.scss
//
.team {
    transition: all 0.5s;
    .team-social {
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 0;
        transition: all 0.5s;
        transform: scale(0.2);
    }
    &:hover {
        .team-social {
            opacity: 1;
            transform: scale(1.1);
        }
    }
}

//profile
.avatar-profile {
    position: relative;
    top: -80px;
}