//
// testi.scss
//
.customer-testi {
    cursor: e-resize;
    .content {
        &:before {
            content: "";
            position: absolute;
            top: 30px;
            left: 0;
            margin-left: 13px;
            box-sizing: border-box;
            border: 8px solid $dark;
            border-color: transparent $white $white transparent;
            transform-origin: 0 0;
            transform: rotate(135deg);
            box-shadow: 2px 2px 2px -1px rgba($dark, 0.15);
        }
    }
    .quote {
        i {
            position: absolute;
            top: -90px;
            left: -30px;
            opacity: 0.05;
            font-size: 140px;
        }
    }
}

.tns-nav {
    text-align: center;
    margin-top: 10px;
    button {
        padding: 4px;
        opacity: 1;
        margin: 3px;
        border-radius: 10px;
        background: rgba($primary, 0.5) !important;
        transition: all 0.5s ease;
        border: 2px solid rgba($white, 0.5);
        &.tns-nav-active {
            background: $primary !important;
            border-color: $primary;
        }
    }
}

.testi-carousel {
    .owl-nav {
        position: absolute;
        top: -80px;
        right: 0;
        button {
            i.mdi {
                background: rgba($primary, 0.1);
                color: $primary;
            }
            &:hover {
                i.mdi {
                    background: $primary;
                    color: $white;
                }
            }
        }
        @media (max-width: 575.98px) {
            display: none;
        }
    }
}

.testi-carousel, 
.owl-roadmap {
    .owl-nav {
        button {
            border-radius: 50% !important;
            i.mdi {
                display: inline-block;
                width: 32px;
                height: 32px;
                line-height: 32px;
                border-radius: 50% !important;
            }
            &:hover {
                i.mdi {
                    background: $primary;
                    color: $white;
                }
            }
        }
    }
}

//Slick slider
.slick-slider {
    overflow-x: hidden !important;
    cursor: pointer;
}

//Switer Slider
.swiper-container {
    width: 100%;
    height: 100%;
    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

//Roadmap
.tiny-timeline {
    position: relative;
    width: 100%;
    margin: 0 auto;
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        top: 17px;
        left: 0;
        height: 2px;
        background: $primary;
    }
    .item-box {
        margin: 70px 15px;
        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            background: $primary;
        }
        &:before {
            width: 2px;
            height: 46px;
            top: -46px;
            transform: translateX(-50%);
        }
        &:after {
            width: 13px;
            height: 13px;
            top: -59px;
            border-radius: 3px;
            transform: translateX(-50%) rotate(45deg);
        }
    }
}